/**
 * Created by Ivan on 23.08.2024
 */

var Pinball = function (mission) {
    cleverapps.EventEmitter.call(this);
    this.mission = mission;
    this.renew();
};

Pinball.prototype = Object.create(cleverapps.EventEmitter.prototype);
Pinball.prototype.constructor = Pinball;

Pinball.prototype.renew = function () {
    this.game = new PinballGame(this);
    this.currentGoal = this.calcCurrentGoal();
    this.checkComplete();
};

Pinball.prototype.calcCurrentGoal = function () {
    var goals = Pinball.GOALS;
    var index = 0;

    while (index < goals.length && this.mission.result >= goals[index].percent * Pinball.TICKETS_GOAL) {
        index++;
    }
    return index;
};

Pinball.prototype.checkComplete = function () {
    if (this.currentGoal === Pinball.GOALS.length) {
        this.mission.complete();
        return true;
    }

    return false;
};

Pinball.prototype.givePrize = function () {
    return this.currentGoal++;
};

Pinball.prototype.getTicketsAmount = function () {
    return Math.min(Pinball.TICKETS_GOAL, this.mission.result);
};

Pinball.prototype.isCompleteGoal = function () {
    return this.mission.result >= Pinball.GOALS[this.currentGoal].percent * Pinball.TICKETS_GOAL;
};

Pinball.prototype.buyGame = function () {
    this.mission.logic.updateChips(-1);
};

Pinball.prototype.onUpdateChips = function () {
    this.trigger("updateChips");
};

Pinball.prototype.acceptChipsOffer = function () {
    cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.PINBALL_CHIPS, function () {
        this.mission.logic.updateChips(1);
        cleverapps.adsLimits.watch(AdsLimits.TYPES.PINBALL_CHIPS);
        this.trigger("updateState");
        this.trigger("updateChips", 1);
    }.bind(this), function () {
        this.trigger("updateChips");
    }.bind(this));
};

Pinball.prototype.win = function () {
    var steps = [
        function (f) {
            this.trigger("updateProgress", this.getTicketsAmount(), f);
        }.bind(this),
        function (f) {
            if (this.isCompleteGoal()) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PINBALL_REWARD + this.currentGoal);
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PINBALL_REWARD_COST + this.currentGoal, {
                    value: RewardsList.calcCost(Pinball.GOALS[this.currentGoal].prize)
                });

                this.trigger("givePrize", this.givePrize(), f);
            } else {
                f();
            }
        }.bind(this),
        function (f) {
            this.calcCurrentGoal();
            if (this.checkComplete()) {
                this.trigger("missionCompleted", f);
            } else {
                f();
            }
        }.bind(this)
    ];
    new ActionPlayer(steps).play();
};

Pinball.prototype.startGame = function (startPosition) {
    this.game.stop();
    this.buyGame();
    this.game.run(startPosition);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PINBALL_PLAYED_GAMES + this.game.gamesPlayed);
};

Pinball.prototype.isForceAvailable = function () {
    return !cleverapps.forces.isShown(Forces.PINBALL.id);
};

Pinball.prototype.showForce = function () {
    this.trigger("showForce");
};

Pinball.prototype.isAdAvailable = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.PINBALL_CHIPS) === AdsLimits.STATE_READY
        && cleverapps.rewardedAdsManager.isRewardedAvailable() && !this.mission.currency && !this.checkComplete();
};

Pinball.prototype.isGameAvailable = function () {
    return this.mission.currency > 0 && !this.checkComplete();
};

Pinball.REWARDS = [10, 50, 100, 50, 10];
Pinball.MAX_REWARD = 100;
Pinball.TICKETS_GOAL = 8000;
Pinball.GOALS = [
    {
        percent: 0.3125,
        icon: bundles.pinball.frames.reward1_png,
        prize: RewardsConfig.PinballRewards[0]
    },
    {
        percent: 0.6875,
        icon: bundles.pinball.frames.reward2_png,
        prize: RewardsConfig.PinballRewards[1]
    },
    {
        percent: 1,
        icon: bundles.pinball.frames.reward3_png,
        prize: RewardsConfig.PinballRewards[2]
    }
];