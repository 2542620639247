/**
 * Created by Reda on 17.09.2024
 */

var AdsTextView = cc.Node.extend({
    ctor: function (pinball) {
        var styles = cleverapps.styles.PinballAdsText;
        this._super();
        this.pinball = pinball;

        var text = this.text = new TextWithIcon("##");
        cleverapps.UI.onClick(text, function () {
            this.pinball.acceptChipsOffer();
        }.bind(this));

        this.addChild(text);
        text.setFontSize(styles.fontSize);
        text.setPositionRound(styles);
    },

    updateState: function () {
        var silent = this.visible === this.pinball.isAdAvailable();
        this.pinball.isAdAvailable() ? this.enable(silent) : this.disable(silent);
    },

    enable: function (silent) {
        if (silent) {
            this.setVisible(true);
        } else {
            this.setScale(0.4);
            this.runAction(
                new cc.Sequence(
                    new cc.Show(),
                    new cc.ScaleTo(0.2, 1)
                )
            );
        }
    },

    disable: function (silent) {
        if (silent) {
            this.setVisible(false);
        } else {
            this.runAction(
                new cc.Sequence(
                    new cc.DelayTime(0.2),
                    new cc.ScaleTo(0.2, 0.4),
                    new cc.Hide()
                )
            );
        }
    }

});

cleverapps.styles.PinballAdsText = {
    fontSize: 55,
    x: {
        align: "center",
        dx: 100
    },
    y: {
        align: "center"
    }
};